<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="sitesCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Email"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email|max:255"
              >
                <b-form-input
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Email"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Select invite type"
            >
              <b-form-radio
                v-if="!isClient"
                v-model="form.is_new_company"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="1"
                class="mb-1"
              >
                New company
              </b-form-radio>
              <b-form-radio
                v-model="form.is_new_company"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="0"
              >
                Existing Company
              </b-form-radio>
            </b-form-group>
            <!--       COMPANY INVITE        -->
            <b-form-group
              v-if="form.is_new_company === '1' && companyRoles.length > 0"
              label="Entity Type"
              label-for="entity-type"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Entity type"
                rules="required"
              >

                <v-select
                  v-model="selectedCompanyRole"
                  :options="companyRoles"
                  :reduce="role => role"
                  placeholder="Select entity type"
                  label="name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.is_new_company === '1' && showParentCompany"
              label="Select parent company"
              label-for="parent-company"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Parent company"
                vid="company_id"
                rules="required"
              >

                <v-select
                  v-model="form.company_id"
                  :options="parentCompanyList"
                  :reduce="company_id => company_id.id"
                  placeholder="Search"
                  label="name"
                  @search="onSearchParentCompany($event, selectedCompanyRole.parent_id)"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.is_new_company === '1'"
              label="Billing Terms"
              label-for="billing-term"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Billing term"
                vid="billing_term"
                rules="required"
              >

                <v-select
                  v-model="form.billing_term"
                  :options="billingTerms"
                  :reduce="billing_term => billing_term.id"
                  placeholder="Billing Terms"
                  label="name"
                  :searchable="false"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--       USER INVITE        -->
            <b-form-group
              v-if="form.is_new_company === '0' && !isClient"
              label="Select company"
              label-for="parent-company"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Company"
                vid="company_id"
                rules="required"
              >

                <v-select
                  v-model="form.company_id"
                  :options="companyList"
                  :searchable="true"
                  :reduce="company_id => company_id.id"
                  placeholder="Search"
                  label="name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="form.is_new_company === '0' && userRoles.length > 0"
              label="User Role"
              label-for="user-type"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="user-type"
                vid="user_role_id"
                rules="required"
              >

                <v-select
                  v-model="form.user_role_id"
                  :options="userRoles"
                  :reduce="user_role_id => user_role_id.id"
                  placeholder="Select user role"
                  label="name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="isLoading"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormRadio,
} from 'bootstrap-vue'
import { required, email, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      form: {
        email: '',
        is_new_company: null,
        company_id: null,
        billing_term: null,
        user_role_id: null,
      },
      isLoading: false,
      selectedCompanyRole: null,
      required,
      emailRule: email,
      max,
      isClient: null,
    }
  },
  computed: {
    ...mapFields('invite', [
      'companyRoles',
      'parentCompanyList',
      'companyList',
      'userRoles',
      'billingTerms',
    ]),
    showParentCompany() {
      return !!(this.selectedCompanyRole && this.selectedCompanyRole.parent_id && this.selectedCompanyRole.is_show_company)
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.is_new_company': function (val) {
      this.form.company_id = null
      this.companyList = []
      this.fetchCompanyList({ })
      this.parentCompanyList = []
      if (val === '1') {
        if (this.companyRoles.length === 0) {
          this.fetchCompanyRoles()
        }
        if (this.billingTerms.length === 0) {
          this.fetchBillingTerms()
        }
      } else if (val === '0') {
        this.fetchUserRoles()
      }
    },
  },
  async created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.isClient = userData.roles.includes('client')
  },
  methods: {
    ...mapActions('invite', [
      'fetchBillingTerms',
      'fetchCompanyRoles',
      'fetchUserRoles',
      'fetchParentCompanyList',
      'fetchCompanyList',
    ]),
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
    }),
    onSearchParentCompany(query, id) {
      if (!query) {
        return
      }
      this.fetchParentCompanyList({ query, id })
    },
    onSearchCompany(query) {
      if (!query) {
        return
      }
      this.fetchCompanyList({ query })
    },
    async validationForm() {
      const valid = await this.$refs.sitesCreate.validate()
      this.isLoading = true
      if (valid) {
        try {
          const payload = { ...this.form }
          if (this.form.is_new_company === '1') {
            delete payload.user_role_id
          } else if (this.isClient) {
            delete payload.company_id
            delete payload.billing_term
          } else {
            delete payload.billing_term
          }
          await this.$http.post('/user/invite/store', {
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          // TODO need chnage to sites-list after create this route
          this.$router.push({ name: 'home' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.sitesCreate.setErrors(fields)
          }
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetUserState()

    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
